import React from 'react';
import Album from './Album';
import { Button, Container, Jumbotron } from 'reactstrap';

const Main = ({ album }) => {

const handleClick = (e,url) => {
    e.preventDefault();
    window.open(url);
};

const handleClickCompanion = (e,url) => {
    e.preventDefault();
    window.open(url, "companionWindow",
    `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=630,
     height=840`);
};

    return (
        <main role="main">
            <Jumbotron className="text-center">
                <Container>
                    <h1 className="jumbotron-heading">Akamai Identity Cloud Demos</h1>
                    <p className="lead text-muted">
                        This is a repository with all the Akamai Identity Cloud demo applications
                        which serve as a great way to show the features of the Identity Cloud components.
                        Choose "JS/API Version" or "Hosted Login" depending on which integration
                        type you would like to showcase.
                    </p>
                    <p>
                        <Button color="primary" className="mx-1 my-2" onClick={(e) => handleClick(e,"https://www.akamai.com/us/en/products/security/identity-cloud.jsp")}>
                            Learn more about Akamai Identity Cloud
                        </Button>
                    </p>
                </Container>
            </Jumbotron>
            <div className="setting-sidebar" id="setting-icon">
                        <div>
                        <Button color="primary" className="mx-1 my-2" onClick={(e) => handleClickCompanion(e,"https://companion.aicdemos.com")}>
                            Launch Companion App
                        </Button>
                        </div>
                    </div>
            <Album album={album} />
        </main>
    );
};

export default Main;
