import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardImg,
    Badge,
    CardBody,
    Col,
    Container,
    Row,
    UncontrolledTooltip
} from 'reactstrap';

const Album = ({ album }) => {
    const handleClick = (e, url) => {
        e.preventDefault();
        if (url) {
            window.open(url);
        }
    };

    function Tooltip(props) {
        if (!props.url) {
            return (
                <UncontrolledTooltip
                    placement={props.placement}
                    target={props.target}
                >
                    Not available yet
                </UncontrolledTooltip>
            );
        }
        return null;
    }

    return (
        <div className="album py-5 bg-light">
            <Container>
                <Row className="justify-content-center">
                    {album.map((item, key) => {
                        return (
                            <Col md="4" key={key}>
                                <Card className="mb-4 box-shadow">
                                    <CardImg
                                        top
                                        width="100%"
                                        src={item.src}
                                        alt={item.altText}
                                    />
                                    <CardBody>
                                        <h4>
                                            <Badge>{item.description}</Badge>
                                        </h4>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <ButtonGroup>
                                                <Button
                                                    outline
                                                    color="primary"
                                                    size="sm"
                                                    onClick={e =>
                                                        handleClick(
                                                            e,
                                                            item.api_js_url
                                                        )
                                                    }
                                                    id={'btnApiJs' + key}
                                                >
                                                    JS/API Version
                                                </Button>
                                                <Tooltip
                                                    url={item.api_js_url}
                                                    target={'btnApiJs' + key}
                                                    placement="bottom-start"
                                                />
                                                <Button
                                                    outline
                                                    color="primary"
                                                    size="sm"
                                                    onClick={e =>
                                                        handleClick(
                                                            e,
                                                            item.hosted_url
                                                        )
                                                    }
                                                    id={'btnHosted' + key}
                                                >
                                                    Hosted Login
                                                </Button>
                                                <Tooltip
                                                    url={item.hosted_url}
                                                    target={'btnHosted' + key}
                                                    placement="bottom-end"
                                                />
                                            </ButtonGroup>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default Album;
